<template>
  <div class="context-box">
    <van-image src="https://hires.oss-cn-shenzhen.aliyuncs.com/static/not_found.png"/>
    <p>您访问的页面不存在...</p>
    <router-link :to="{path: '/'}">
      <van-button round block type="info">返回登录页</van-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {

    };
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.context-box{
  width: 90%;
  text-align: center;
  margin: 100px auto;
}
</style>